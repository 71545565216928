<template>
    <a class="btn-link" :href="href" :target="target">
        <span>
            <slot name="default">
                Смотреть коллекцию
            </slot>
        </span>
    </a>
</template>

<script>
    export default {
        name: 'btn-link',
        props: ["href", "target", "label"]
    }
</script>

<style scoped lang="scss">
    #app {
        .btn-link {
            display: inline-block;
            width: 229px;
            border-radius: 60px;
            padding: 1px;
            box-sizing: border-box;
            background: linear-gradient(167.7deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0) 100%); //yellow;

            &:hover {
                background: linear-gradient(324.99deg, rgba(255, 255, 255, 0.1) -27.32%, rgba(255, 255, 255, 0.03) 103.72%);
            }

            span {
                display: inline-block;
                height: 46px;
                width: 100%;
                box-sizing: border-box;
                line-height: 46px;
                text-align: center;
                background: conic-gradient(from 155.62deg at 50% 50%, #102235 0deg, #18293A 184.19deg, #102235 360deg, #102235 360deg);
                border-radius: 60px;
                white-space: nowrap;

                @include transition();

                &:hover {
                    //background: $taupe-color;
                    color: $white-color;
                }
            }
        }
    }
</style>
