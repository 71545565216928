<template>
    <div class="subscription-confirmation">
        <p class="subscription-confirmation__header">Спасибо!</p>
        <p>Вы успешно подписались на нашу рассылку.</p>
    </div>
</template>

<script>
    export default {
        name: 'subscription-confirmation',
    }
</script>

<style lang="scss">

    .subscription-confirmation {
        padding: 0 0 20px 0;

        &__header {
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            text-transform: uppercase;
            color: #000;
        }
    }
</style>
