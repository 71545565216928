export default {
	hit() {
		console.log("GA hit try: " + window.location.href);
		const ga = this.gaCounter();
		if (ga) {
			ga('set', 'page', window.location.href);
			console.log("GA hit sent: " + window.location.href);
		}
	},

	gaCounter() {
		if ( typeof window.ga !== "undefined" ) return window.ga;
		return null;
	}
};
