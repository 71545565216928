const config = {
    YA_COUNTER_NAME: "yaCounter22537834",
    IS_STUB: false,

    SUBSCRIPTION_URL: "subscribe.php",

    WIDTH_SM: 600,
    WIDTH_MD: 960,
    WIDTH_LG: 1280-16,
    WIDTH_XL: 1700-16,
};
export default config;

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
if ( params.live ) config.IS_STUB = false;
