<template>
    <v-app :class="{stub:isStub}">
        <the-header v-if="!isStub"/>
        <v-main>
            <transition name="route" mode="out-in">
                <router-view/>
            </transition>
        </v-main>
        <the-footer v-if="!isStub && hasPaused"/>
    </v-app>
</template>

<script>
    import AOS from 'aos';
    import TheHeader from "./layout/the-header";
    import TheFooter from "./layout/the-footer";
    import config from "./config";

    export default {
        components: {TheHeader, TheFooter},
        data: () => ({
            swiperReinitCount: 0,
            hasPaused: false
        }),
        computed: {
            isStub() {
                return config.IS_STUB;
            }
        },
        methods: {
            onResize() {
                //console.log("App onResize");

                this.$store.commit('updateWindowWidth');
                this.$store.commit('updateWindowHeight');

                // на iOS чтобы попадало во весь экран на телефоне 100vh:
                //нужно добавить css переменную vh в main.scss:
                //:root {
                //--vh: 1vh;
                //}
                //после вместо vh использовать var(--vh, 1vh)
                //например всето 100vh = calc(var(--vh, 1vh)*100)
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);

                this.reinitSwipers();
            },
            reinitSwipers() {

                /*
                    TODO важно! уродский свафпер не пересоздается при ресайзе/уничтожении в v-if
                    TODO приходится его полностью пересоздавать (точнее обновлять его ref)
                    https://github.com/surmon-china/vue-awesome-swiper/issues/774
                    https://github.com/surmon-china/vue-awesome-swiper/issues/687
                */
                this.swiperReinitCount++;   // это важно!

                setTimeout(() => {
                    // обновим референс на свайпер для доступа снаружи
                    // TODO пройти по всем свайперам на странице и реинитить их
                    //this.$store.state.appSwiper = this.swiper;
                    //console.log("APP SWIPER reinited", this.swiper);
                    let keys = Object.keys(this.$store.state.swipers);
                    keys.forEach(key => {
                        if (this.$store.state.swipers[key]) this.$store.state.swipers[key].update();
                    });
                }, 300);
            }
        },
        mounted() {
            window.addEventListener("resize", this.onResize);
            this.onResize();

            setTimeout(() => {
                AOS.init({
                    //startEvent: 'OnMapLoad',
                    disable: false,
                    useClassNames: false,
                    duration: 750,
                    offset: 0,
                    once: true,
                    mirror: false,
                });
            }, 50);

            setTimeout(() => this.hasPaused = true, 500);
        },
        created() {
        },
        destroyed() {
            window.removeEventListener("resize", this.onResize);
        }
    }
</script>

<style lang="scss">

    /** Глобальные стили **/

    body {
        background: linear-gradient(180deg, $bg-primary 0%, $bg-secondary 100%);
        color: $text-color;
        font-family: $body-font-family, sans-serif;
        font-size: 14px;
        font-weight: normal;
        line-height: 135%;
        margin: 0;
        padding: 0;
        min-height: 100vh;

        @include up($md) {
            font-size: 15px;
            font-weight: normal;
            line-height: 160%;
        }
    }

    h1 {
        font-family: $header-font-family;
        font-weight: 400;
        font-size: 40px;
        line-height: 36px;
        text-transform: uppercase;

        @include up($md) {
            font-size: 64px;
            line-height: 70.4px;
        }

        @include up($xl) { // важно! xl
            font-size: 104px;
            line-height: 114.4px;
        }
    }

    h2 {
        font-family: $header-font-family;
        font-size: 40px;
        line-height: 90%;
        font-weight: normal;

        @include up($md) {
            font-weight: 400;
            font-size: 64px;
            line-height: 70.4px;
        }
    }

    h3 {
        // надзаголовок
        font-family: $header-font-family;
        font-size: 20px;
        line-height: 20px;
        font-style: normal;
        font-weight: normal;
        text-transform: lowercase;

        @include up($md) {
            font-size: 24px;
            line-height: 24px;
        }
    }

    h4 {
        // caption
        font-family: $body-font-family;
        font-size: 15px;
        line-height: 15px;
        font-style: normal;
        font-weight: normal;
        color: $white-color;
        text-transform: uppercase;
    }

    .parag {
        font-size: 20px;
        font-weight: 400;
        line-height: 27px;
    }

    .txt {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
    }

    .svg-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        color: inherit;
        vertical-align: middle;
        fill: none;
        stroke: currentColor;
    }

    .svg-fill {
        fill: currentColor;
        stroke: none;
    }

    .svg-up {
        /* default */
        transform: rotate(0deg);
    }

    .svg-right {
        transform: rotate(90deg);
    }

    .svg-down {
        transform: rotate(180deg);
    }

    .svg-left {
        transform: rotate(-90deg);
    }

    #app {
        /* todo remove this to theme, see plugins/vuetify.js */
        background: transparent;
        color: $text-color;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        min-width: 320px;
        line-height: 135%;

        padding-top: 54px;
        @include up($md) {
            padding-top: 115px;
        }

        &.stub {
            padding: 0;
        }

        &::before {
            position: fixed;
            content: "";
            top: 0;
            right: 0;
            width: 100vw;
            height: 100vh;
            background-image: url("../src/assets/bg-stars.png");
            background-repeat: repeat;
            z-index: 0;

            animation: bg-move 1000s linear infinite;
            //animation-delay: 0.75s;
            //transform-origin: 300px 300px;
            //opacity: 0;
            //animation-fill-mode: forwards;
        }

        /*&::after {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-image: url("../src/assets/bg-stars.png");
            background-repeat: repeat;
            z-index: 0;
            opacity: 0.5;

            animation: bg-move2 90s linear infinite;
            //animation-delay: 0.75s;
            //transform-origin: 300px 300px;
            //opacity: 0;
            //animation-fill-mode: forwards;
        }*/

        /*@keyframes bg-move2 {
            from {
                background-position: 300px 0px;
            }
            to {
                background-position: 300px -100px;
            }
        }*/
        @keyframes bg-move {
            from {
                background-position: 0px;
            }
            to {
                background-position: 0 10000px;
            }
        }

        p {
            margin: 0;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        li {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        a {
            text-decoration: none;
            color: inherit;
        }

        button {
            border: none;
            cursor: pointer;
        }
    }

    .route-enter-active, .route-leave-active {
        transition: opacity .3s
    }

    .route-enter, .route-leave-to {
        opacity: 0;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>
