export default [
    {
        id: "aries",
        label: "Овен",
        img: require('@/assets/signs/aries.png'),
        imgMD: require('@/assets/signs-md/aries.png'),
        //imgCircle: require('@/assets/signs-circle/aries.png'),
        preview: "21 марта – 20 апреля, стихия Огонь",
        text: "Любовь\n"
            + "В декабре Овны будут особенно общительны, что поможет вам не только в деловой жизни, но и в личной. Вообще, в этом месяце личная жизнь будет тесно связана с карьерой: успех в работе притянет позитив на любовном фронте. Отношениями лучше заниматься в первой половине декабря.\n"
            + "Финансы\n"
            + "Овны будут стараться уйти от повседневной рутины и отвлечься от забот, поэтому декабрь - хороший месяц для отпуска. Вы будете стремиться подробно обсуждать насущные проблемы с окружающими, но постарайтесь не переборщить с количеством информации. В целом, это время больше подходит для долгосрочного планирования, чем для активных действий. Но во второй половине месяца обстоятельства вынудят Овнов вернуться к делам.\n"
            + "Здоровье\n"
            + "Не рекомендуется игнорировать свое здоровье: во второй половине месяца оно особенно уязвимо.",
        slides: [
            {
                img: require('@/assets/collection-slider/aries/1.jpg'),
                name: "Юбка из вискозы",
                price: "1 999",
                link: "https://zarina.ru/catalog/product/1329209209-61/"
            },
            {
                img: require('@/assets/collection-slider/aries/2.jpg'),
                name: "Свитер с шерстью",
                price: "3 599",
                link: "https://zarina.ru/catalog/product/1420643843-87/"
            },
            {
                img: require('@/assets/collection-slider/aries/3.jpg'),
                name: "Стеганное пальто",
                price: "5 999",
                link: "https://zarina.ru/catalog/product/1421490140-36/"
            },
        ]
    },
    {
        id: "taurus",
        label: "Телец",
        img: require('@/assets/signs/taurus.png'),
        imgMD: require('@/assets/signs-md/taurus.png'),
        //imgCircle: require('@/assets/signs-circle/taurus.png'),
        preview: "21 апреля — 20 мая, стихия Земля",
        text: "Любовь\n"
            + "В декабре Тельцы будут особенно эмоциональны. Декабрь - хорошее время для путешествий: одинокие представители знака могут завести интересное знакомство, а у семейных Тельцов совместный отдых с партнером будет особенно романтичным.\n"
            + "Финансы\n"
            + "Тельцы постараются изменить то, что их не устраивает. Это касается не только жизненных аспектов или каких-то предметов, но и людей и отношений с ними. В декабре Тельцы будут особенно внимательны к мелочам и недоверчивы в бизнесе, стараясь докопаться до самой сути вещей. Сейчас хорошее время для начала новых проектов.\n"
            + "Здоровье\n"
            + "Постарайтесь больше отдыхать: ваше эмоциональное состояние будет не самым стабильным.",
        slides: [
            {
                img: require('@/assets/collection-slider/taurus/1.jpg'),
                name: "Платье",
                price: "3 999",
                link: "https://zarina.ru/catalog/product/1421640540-17/"
            },
            {
                img: require('@/assets/collection-slider/taurus/2.jpg'),
                name: "Сумка тоут",
                price: "2 599",
                link: "https://zarina.ru/catalog/product/147020001-50/"
            },
            {
                img: require('@/assets/collection-slider/taurus/3.jpg'),
                name: "Пальто из экокожи",
                price: "6 999",
                link: "https://zarina.ru/catalog/product/1420411111-17/"
            },
        ]
    },
    {
        id: "gemini",
        label: "Близнецы",
        img: require('@/assets/signs/gemini.png'),
        imgMD: require('@/assets/signs-md/gemini.png'),
        //imgCircle: require('@/assets/signs-circle/gemini.png'),
        preview: "21 мая — 21 июня, стихия Воздух",
        text: "Любовь\n"
            + "Близнецы будут придавать большое значение отношениям с окружающими людьми. Скорее всего, вы переоцениваете важность чужого мнения о вас, постарайтесь избегать зависимых отношений. Если вы одиноки, то у вас есть много шансов найти партнера, который поможет укрепить ваше материальное положение. Постарайтесь контролировать свою ревность.\n"
            + "Финансы\n"
            + "Вы будете довольно общительны и склонны к компромиссам, что поможет вам найти взаимопонимание с коллегами по любым рабочим вопросам.\n"
            + "Здоровье\n"
            + "Ваше здоровье вас не подведет, постарайтесь больше отдыхать - в декабре возможно переутомление.",
        slides: [
            {
                img: require('@/assets/collection-slider/gemini/1.jpg'),
                name: "Худи из хлопка",
                price: "2 399",
                link: "https://zarina.ru/catalog/product/1329503406-9/"
            },
            {
                img: require('@/assets/collection-slider/gemini/2.jpg'),
                name: "Брюки",
                price: "1 999",
                link: "https://zarina.ru/catalog/product/1329503740-9/"
            },
            {
                img: require('@/assets/collection-slider/gemini/3.jpg'),
                name: "Удлиненный пуховик",
                price: "7 999",
                link: "https://zarina.ru/catalog/product/1420413113-1/"
            },
        ]
    },
    {
        id: "cancer",
        label: "Рак",
        img: require('@/assets/signs/cancer.png'),
        imgMD: require('@/assets/signs-md/cancer.png'),
        //imgCircle: require('@/assets/signs-circle/cancer.png'),
        preview: "22 июня — 22 июля, стихия Вода",
        text: "Любовь\n"
            + "Декабрь принесёт одиноким Ракам немало интересных встреч, постарайтесь не упустить шансы построить отношения, не бойтесь проявлять инициативу. Семейных представителей знака ждет гармоничный и спокойный период.\n"
            + "Финансы\n"
            + "В этом месяце ваш профессионализм и организаторские способности проявятся особенно ярко. Если вы находитесь в поиске работы, то сможете найти хорошее предложение. Благодаря своему перфекционизму и активности, Раки покажут отличные результаты на работе. Постарайтесь закончить все текущие дела.\n"
            + "Здоровье\n"
            + "В этом месяце вырастает вероятность получения травм, будьте аккуратны.",
        slides: [
            {
                img: require('@/assets/collection-slider/cancer/1.jpg'),
                name: "Юбка из экозамши",
                price: "2 599",
                link: "https://zarina.ru/catalog/product/1421220220-94/"
            },
            {
                img: require('@/assets/collection-slider/cancer/2.jpg'),
                name: "Блузка из экозамши",
                price: "3 599",
                link: "https://zarina.ru/catalog/product/1421220620-94/"
            },
            {
                img: require('@/assets/collection-slider/cancer/3.jpg'),
                name: "Стеганное пальто",
                price: "5 999",
                link: "https://zarina.ru/catalog/product/1420408108-63/"
            },
        ]
    },
    {
        id: "leo",
        label: "Лев",
        img: require('@/assets/signs/leo.png'),
        imgMD: require('@/assets/signs-md/leo.png'),
        //imgCircle: require('@/assets/signs-circle/leo.png'),
        preview: "23 июля — 22 августа, стихия Огонь",
        text: "Любовь\n"
            + "Львов ждет гармоничная личная жизнь. Постарайтесь не быть слишком требовательны к партнеру. В декабре Львы будут коммуникабельны и общительны, с удовольствием проводя время в компании друзей.\n"
            + "Финансы\n"
            + "В декабре Львов потянет на авантюры и неосторожные поступки. Однако ваше внимание к деталям поможет избежать возможных ошибок. В целом, в декабре вы больше времени будете уделять семье и развлечениям, а не работе.\n"
            + "Здоровье\n"
            + "Здоровье не будет доставлять беспокойства.",
        slides: [
            {
                img: require('@/assets/collection-slider/leo/1.jpg'),
                name: "Джемпер с узором",
                price: "2 599",
                link: "https://zarina.ru/catalog/product/1420620852-12/"
            },
            {
                img: require('@/assets/collection-slider/leo/2.jpg'),
                name: "Брюки из вискозы",
                price: "2 799",
                link: "https://zarina.ru/catalog/product/1420211712-50/"
            },
            {
                img: require('@/assets/collection-slider/leo/3.jpg'),
                name: "Пуховик с капюшоном",
                price: "4 599",
                link: "https://zarina.ru/catalog/product/1420419119-37/"
            },
        ]
    },
    {
        id: "virgo",
        label: "Дева",
        img: require('@/assets/signs/virgo.png'),
        imgMD: require('@/assets/signs-md/virgo.png'),
        //imgCircle: require('@/assets/signs-circle/virgo.png'),
        preview: "23 августа — 22 сентября, стихия Земля",
        text: "Любовь\n"
            + "В декабре Девы будут с удовольствием проводить время дома с семьей, избегая новых мест и людей. В спорных вопросах постарайтесь найти компромисс. Если вы еще одиноки, то у вас есть все шансы встретить свою вторую половинку. Но вам рекомендуется снизить требования к потенциальному партнеру.\n"
            + "Финансы\n"
            + "В этом месяце вы будете подводить итоги своей работы, старясь закончить дела. Не стоит отмахиваться от возникающих идей, они могут быть претворены в жизнь довольно успешно.\n"
            + "Здоровье\n"
            + "Девам рекомендуется поддерживать свои силы правильным питанием и витаминами.",
        slides: [
            {
                img: require('@/assets/collection-slider/virgo/1.jpg'),
                name: "Блузка в рубчик",
                price: "2 999",
                link: "https://zarina.ru/catalog/product/1421642842-27/"
            },
            {
                img: require('@/assets/collection-slider/virgo/2.jpg'),
                name: "Брюки в рубчик",
                price: "2 799",
                link: "https://zarina.ru/catalog/product/1421642712-27/"
            },
            {
                img: require('@/assets/collection-slider/virgo/3.jpg'),
                name: "Пальто из экокожи",
                price: "6 999",
                link: "https://zarina.ru/catalog/product/1420411111-36/"
            },
        ]
    },
    {
        id: "libra",
        label: "Весы",
        img: require('@/assets/signs/libra.png'),
        imgMD: require('@/assets/signs-md/libra.png'),
        //imgCircle: require('@/assets/signs-circle/libra.png'),
        preview: "23 сентября — 22 октября, стихия Воздух",
        text: "Любовь\n"
            + "В декабре Весы будут много общаться, посещая множество мест. В этот период отношения с окружающими выйдут на первый план, скорее всего вы будете пожинать плоды того, каким образом вы выстроили отношения ранее. В целом в декабре Весы будут больше внимание уделять семейным делам, чем рабочим. У одиноких представителей знака есть шансы завести перспективное знакомство с помощью друзей.\n"
            + "Финансы\n"
            + "В этом месяце общение будет особенно продуктивно. Однако Весам будет сложно сосредоточится на чем-то одном, поэтому важные дела лучше отложить.\n"
            + "Здоровье\n"
            + "Возможно небольшое недомогание, но вы легко с этим справитесь.",
        slides: [
            {
                img: require('@/assets/collection-slider/libra/1.jpg'),
                name: "Джемпер с принтом",
                price: "2 599",
                link: "https://zarina.ru/catalog/product/1420636856-41/"
            },
            {
                img: require('@/assets/collection-slider/libra/2.jpg'),
                name: "Джинсы straight",
                price: "2 399",
                link: "https://zarina.ru/catalog/product/1420450750-103/"
            },
            {
                img: require('@/assets/collection-slider/libra/3.jpg'),
                name: "Пуховик с капюшоном",
                price: "5 999",
                link: "https://zarina.ru/catalog/product/1420417117-3/"
            },
        ]
    },
    {
        id: "scorpio",
        label: "Скорпион",
        img: require('@/assets/signs/scorpio.png'),
        imgMD: require('@/assets/signs-md/scorpio.png'),
        //imgCircle: require('@/assets/signs-circle/scorpio.png'),
        preview: "23 октября — 21 ноября, стихия Вода",
        text: "Любовь\n"
            + "Декабрь - хорошее время для коротких поездок, связанных с развлечениями. Скорпионы с удовольствием будут делиться своими чувствами, мыслями и эмоциями с близкими людьми.\n"
            + "Финансы\n"
            + "Ваше внимание будет сосредоточено на денежных вопросах. Сейчас самое время позаботиться об имуществе, финансах и бизнесе. Рекомендуется покупка недвижимости и других ценных вещей, заключение важных сделок.\n"
            + "Здоровье\n"
            + "Старайтесь побольше отдыхать и не нервничать, в декабре лишний стресс рискует привести за собой лишние неприятности.",
        slides: [
            {
                img: require('@/assets/collection-slider/scorpio/1.jpg'),
                name: "Водолазка в рубчик",
                price: "1 799",
                link: "https://zarina.ru/catalog/product/1420616816-64/"
            },
            {
                img: require('@/assets/collection-slider/scorpio/2.jpg'),
                name: "Брюки с ремнем",
                price: "2 999",
                link: "https://zarina.ru/catalog/product/1421210710-62/"
            },
            {
                img: require('@/assets/collection-slider/scorpio/3.jpg'),
                name: "Пуховик из экокожи",
                price: "6 999",
                link: "https://zarina.ru/catalog/product/1420430130-62/"
            },
        ]
    },
    {
        id: "sagittarius",
        label: "Стрелец",
        img: require('@/assets/signs/sagittarius.png'),
        imgMD: require('@/assets/signs-md/sagittarius.png'),
        //imgCircle: require('@/assets/signs-circle/sagittarius.png'),
        preview: "22 ноября — 20 декабря, стихия Огонь",
        text: "Любовь\n"
            + "Стрельцы могут быть несколько расточительны в декабре, из-за чего могут возникнуть конфликты с партнером. Обратите особое внимание на что вы тратите свое время, силы и финансы, не растрачивайте ресурсы понапрасну.\n"
            + "Финансы\n"
            + "Стрельцы смогут укрепить свой авторитет на работе и заслужить уважение коллег. Вы сможете легко находить решения для самых непростых задач. Декабрь - хорошее время для сделок. Ваш доход должен увеличиться.\n"
            + "Здоровье\n"
            + "Звезды обещают Стрельцам крепкое здоровье в декабре.",
        slides: [
            {
                img: require('@/assets/collection-slider/sagittarius/1.jpg'),
                name: "Свитер с объемным воротом",
                price: "1 999",
                link: "https://zarina.ru/catalog/product/1421613813-9/"
            },
            {
                img: require('@/assets/collection-slider/sagittarius/2.jpg'),
                name: "Брюки из экокожи",
                price: "2 599",
                link: "https://zarina.ru/catalog/product/1329204725-27/"
            },
            {
                img: require('@/assets/collection-slider/sagittarius/3.jpg'),
                name: "Удлиненный пуховик",
                price: "7 999",
                link: "https://zarina.ru/catalog/product/1420413113-1/"
            },
        ]
    },
    {
        id: "capricorn",
        label: "Козерог",
        img: require('@/assets/signs/capricorn.png'),
        imgMD: require('@/assets/signs-md/capricorn.png'),
        //imgCircle: require('@/assets/signs-circle/capricorn.png'),
        preview: "21 декабря — 19 января, стихия Земля",
        text: "Любовь\n"
            + "Козероги будут особенно притягательны в этот период. Одиноким представителям знака не составит труда начать отношения, было бы желание. Однако, возможно, что вы будете предъявлять завышенные требования к партнеру, постарайтесь этого избегать.\n"
            + "Финансы\n"
            + "В декабре Козероги захотят отдохнуть, проводя время в одиночестве или с семьей. В это время лучше заканчивать текущие проекты и не начинать новые. Сейчас ваша интуиция особенно сильна, прислушайтесь к ней и по рабочим вопросам. Возможны задержки в делах. Постарайтесь оптимизировать свои траты.\n"
            + "Здоровье\n"
            + "Опасайтесь мелких травм, возможных из-за вашей невнимательности.",
        slides: [
            {
                img: require('@/assets/collection-slider/capricorn/1.jpg'),
                name: "Джемпер в рубчик",
                price: "3 599",
                link: "https://zarina.ru/catalog/product/1420634834-10/"
            },
            {
                img: require('@/assets/collection-slider/capricorn/2.jpg'),
                name: "Брюки в рубчик",
                price: "2 799",
                link: "https://zarina.ru/catalog/product/1420634714-10/"
            },
            {
                img: require('@/assets/collection-slider/capricorn/3.jpg'),
                name: "Куртка из экокожи",
                price: "5 999",
                link: "https://zarina.ru/catalog/product/1421493143-3/"
            },
        ]
    },
    {
        id: "aquarius",
        label: "Водолей",
        img: require('@/assets/signs/aquarius.png'),
        imgMD: require('@/assets/signs-md/aquarius.png'),
        //imgCircle: require('@/assets/signs-circle/aquarius.png'),
        preview: "20 января — 19 февраля, стихия Воздух",
        text: "Любовь\n"
            + "В декабре в личной жизни Водолеев могут наступить изменения. Также сейчас вероятно, что какая-то скрываемая информация выйдет наружу. Будьте осторожны при выборе партнера, он может оказаться умелым манипулятором.\n"
            + "Финансы\n"
            + "В декабре особенно успешна будет работе в коллективе, а не индивидуальная. В целом, сейчас вы будете стараться закрыть дела этого года максимально успешно. Водолею рекомендуется еще раз внимательно обдумать свои планы и цели, возможно понадобится их корректировка. Не бойтесь советоваться с близкими, ваша скрытность будет производить не самое благоприятное впечатление на окружающих.\n"
            + "Здоровье\n"
            + "Серьезных проблем со здоровьем не предвидится.",
        slides: [
            {
                img: require('@/assets/collection-slider/aquarius/1.jpg'),
                name: "Кардиган с шерстью",
                price: "3 599",
                link: "https://zarina.ru/catalog/product/1420640840-87/"
            },
            {
                img: require('@/assets/collection-slider/aquarius/2.jpg'),
                name: "Трикотажные брюки",
                price: "2 599",
                link: "https://zarina.ru/catalog/product/1420641711-47/"
            },
            {
                img: require('@/assets/collection-slider/aquarius/3.jpg'),
                name: "Пальто пуховик",
                price: "7 999",
                link: "https://zarina.ru/catalog/product/1420409109-36/"
            },
        ]
    },
    {
        id: "pisces",
        label: "Рыбы",
        img: require('@/assets/signs/pisces.png'),
        imgMD: require('@/assets/signs-md/pisces.png'),
        //imgCircle: require('@/assets/signs-circle/pisces.png'),
        preview: "20 февраля — 20 марта, стихия Вода",
        text: "Любовь\n"
            + "Декабрь принесет Рыбам много новых друзей, присмотритесь к ним внимательнее, ведь среди них может оказаться ваш потенциальный партнер. Если вы хотите построить отношения в этом месяце, то берите инициативу в свои руки. Гармония в семье ожидает тех представителей знака, кто не будет забывать о своих домашних обязанностях.\n"
            + "Финансы\n"
            + "Рыбам рекомендуется сосредоточиться на своей репутации и общественном положении. Подумайте над своими долгосрочными планами, возможно что-то для их достижения можно сделать уже сейчас. В декабре Рыбы могут получить повышение по службе или прибавку в доходах. Месяц подходит для открытия своего бизнеса и поиска инвесторов или партнеров.\n"
            + "Здоровье\n"
            + "В декабре здоровью Рыб не будет угрожать что-то серьезное.",
        slides: [
            {
                img: require('@/assets/collection-slider/pisces/1.jpg'),
                name: "Кардиган с карманами",
                price: "2 999",
                link: "https://zarina.ru/catalog/product/1420623825-41/"
            },
            {
                img: require('@/assets/collection-slider/pisces/2.jpg'),
                name: "Трикотажные брюки",
                price: "2 399",
                link: "https://zarina.ru/catalog/product/1420623702-41/"
            },
            {
                img: require('@/assets/collection-slider/pisces/3.jpg'),
                name: "Пуховик с поясом",
                price: "4 999",
                link: "https://zarina.ru/catalog/product/1420422122-41/"
            },
        ]
    },
];
