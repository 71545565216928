export default [
    {
        img: require('@/assets/collection-slider/1.jpg'),
        name: "Футболка",
        price: "1 999",
        link: "https://zarina.ru/catalog/clothes/106988-50/"
    },
    {
        img: require('@/assets/collection-slider/2.jpg'),
        name: "Название товара",
        price: "2 199",
        link: "https://zarina.ru/catalog/clothes/112576-33/"
    },
    {
        img: require('@/assets/collection-slider/3.jpg'),
        name: "Название товара",
        price: "1 499",
        link: "https://zarina.ru/catalog/clothes/112391-33/"
    },
    {
        img: require('@/assets/collection-slider/4.jpg'),
        name: "Название товара",
        price: "1 999",
        link: "https://zarina.ru/catalog/clothes/112391-33/"
    },
];