/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<path pid="0" d="M17 8H1m0 0c2.407.533 7.222 2.48 7.222 6M1 8c2.407-.533 7.222-2.48 7.222-6" _stroke="#fff"/>'
  }
})
