import Vue from 'vue';
import VueRouter from 'vue-router';
//import Home from '../views/Home.vue';
//import Stub from '../views/Stub.vue';
import metrika from "@/utils/metrika";
import ga from "@/utils/ga";
import config from '../config';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
    },
    {
        path: '/sign/:id',
        name: 'Sign',
        props: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/Sign.vue')
    }
];

const routesStub = [
    {
        path: '*',
        name: 'Stub',
        component: () => import(/* webpackChunkName: "about" */ '../views/Stub.vue')
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: config.IS_STUB ? routesStub : routes
});

const scrollToTop = () => {
    window.scrollTo({
        'behavior': undefined, //'smooth',
        'left': 0,
        'top': 0
    });
};

router.afterEach((to, from) => {
    setTimeout(() => {
        if (to?.name !== from?.name) scrollToTop();

        metrika.hit();
        ga.hit();

    }, 300);
});

export default router;
