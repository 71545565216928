/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping-bag': {
    width: 12,
    height: 13,
    viewBox: '0 0 12 13',
    data: '<path pid="0" d="M11.204 12.106l-.912-8.686a.357.357 0 00-.355-.32H8.223v-.39C8.223 1.49 7.232.5 6.013.5c-1.218 0-2.21.991-2.21 2.21v.39H2.09a.357.357 0 00-.356.32l-.912 8.686a.358.358 0 00.355.394h9.672a.357.357 0 00.355-.394zM4.518 2.71c0-.825.67-1.496 1.495-1.496.825 0 1.496.671 1.496 1.496v.39H4.518v-.39zm-2.944 9.076l.837-7.971h1.393v.787a.357.357 0 00.714 0v-.787h2.99v.787a.357.357 0 00.715 0v-.787h1.393l.837 7.97H1.574z" _fill="#140E14"/>'
  }
})
