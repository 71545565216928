import signs from "./signs";
import collection from "./collection";

let state = {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,

    aosDelay: 100,

    swipers: {
        'collection-slider': null,
    },

    signs,
    collection
};
export default state;