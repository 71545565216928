import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import "./styles/index.scss";
import './plugins/svgicon';
import './plugins/aos';
import vuetify from './plugins/vuetify';
import VueSocialSharing from 'vue-social-sharing';
Vue.use(VueSocialSharing);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app');
